// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import omit from 'lodash.omit';
import isEmpty from 'lodash.isempty';
import pick from 'lodash.pick';
import debounce from 'lodash.debounce';
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash.get';
import merge from 'lodash.merge';
import cloneDeep from 'lodash/cloneDeep';

import { fetchProject } from '../../api';
import { convertToVehicleSet } from '../../presenters/calculations/calculations';
import zappy from '@zappy-ride/library.react.components';
import { findEquivalentICEforEV } from '../../presenters/vehicles';
import {
  prepareProject,
  customEmissionsForDefaultAssumptions,
} from '../../presenters/project/project';
import { getAssumptionsFromLocations } from '../../presenters/location';
import { replaceValuesOfMatchingProps, cleanupNumber } from '../../utils/functionHelpers';
import { stateAttrs, status } from '../AppState/contants';

const VEHICLE_SETS_ATTR_NAME = 'vehicleSets';
const EDIT_VEHICLE_SET_INDEX_ATTR_NAME = 'editingVehicleSetIndex';
const FIELDS_TO_OMIT_FROM_VEHICLE_SET = [
  VEHICLE_SETS_ATTR_NAME,
  EDIT_VEHICLE_SET_INDEX_ATTR_NAME,
  'assumptionsData',
  'constants',
  'rateSelection',
  'filters',
  'catalogs',
  'electricityData',
  'modal',
  'warnings',
  'selectionIsDirty',
  'zipcode',
  'inADAC',
];

const CALCULATED_FIELDS_TO_KEEP_VALUES = [
  'avgDistancePerDay',
  'kWhPerDayNeeded',
];

const FORM_SET_AVG_DISTANCE_PER_DAY = 'form/setAvgDistancePerDay';
function setAvgDistancePerDay({ payload, setValue, watchedValues }) {
  const { milesPerWorkday, personalMilesPerWorkday } = watchedValues;
  const value = milesPerWorkday + personalMilesPerWorkday;
  setValue(payload.name, value);
}

export const FORM_SET_KWH_PER_DAY_NEEEDED = 'form/setKwhPerDayNeeded';
function setKwhPerDayNeeded({ payload, setValue, watchedValues, getValues }) {
  const { milesPerWorkday, personalMilesPerWorkday, input } = {
    ...getValues(),
    ...watchedValues,
  };

  const value = Math.round(
    (milesPerWorkday + personalMilesPerWorkday) *
      (input.electric_efficiency / 100)
  );
  setValue(payload.name, zappy.formatters.formatAsThousands(value));
}

export const TOGGLE_EMISSIONS = 'form/toggleCustomEmissions';
export const toggleCustomEmissions = ({ setValue, getValues, payload }) => {
  const { emissionsType } = payload;
  const customEmissions = getValues('customEmissions');
  const filterCustomEmissions = getValues('filters.customEmissions');

  if (!customEmissions || !filterCustomEmissions) {
    return;
  }
  const {
    dieselEmissionsinLbsPerGal,
    gasolineEmissionsInLbsPerGal,
    customElectric,
  } = getValues('customEmissions');
  const {
    toggleIce,
    toggleElectric,
    isDiesel,
    customIce
  } = getValues(
    'filters.customEmissions',
  );

  if ((emissionsType === 'ice' && !toggleIce) || customIce === undefined) {
    setValue(
      'filters.customEmissions.customIce',
      isDiesel ? dieselEmissionsinLbsPerGal : gasolineEmissionsInLbsPerGal
    );
  }
  if (emissionsType === 'electric' && !toggleElectric) {
    setValue('filters.customEmissions.customElectric', customElectric);
  }
};

export const FORM_SET_CUSTOM_EMISSIONS = 'form/setCustomEmissions';
function setCustomEmissions({ payload, setValue, getValues }) {
  const isDiesel = getValues('output.fuel') === 'diesel';
  const customEmissions = getValues('customEmissions');
  const filterCustomEmissions = getValues('filters.customEmissions');
  if (!customEmissions || !filterCustomEmissions) {
    return;
  }
  const {
    dieselEmissionsinLbsPerGal,
    gasolineEmissionsInLbsPerGal,
    customElectric,
  } = getValues('customEmissions');
  const { toggleIce, toggleElectric } = getValues('filters.customEmissions');

  if (
    dieselEmissionsinLbsPerGal &&
    gasolineEmissionsInLbsPerGal &&
    !toggleIce
  ) {
    setValue(
      'filters.customEmissions.customIce',
      isDiesel ? dieselEmissionsinLbsPerGal : gasolineEmissionsInLbsPerGal
    );
    setValue('filters.customEmissions.isDiesel', isDiesel);
  }
  if (customElectric && !toggleElectric) {
    setValue('filters.customEmissions.customElectric', customElectric);
  }
}

const FORM_SET_CHARGER_INSTALLATION_COSTS = 'form/setChargerInstallationCosts';
function setChargerInstallationCosts({
  getValues,
  setValue,
  watchedValues,
  calculations,
}) {
  const {
    level2ChargersInstallationCost,
    dcfcChargersInstallationCost,
  } = getValues('assumptionsData', {});

  const {
    customChargerInstallationCostToggler,
    customChargerInstallationCost,
  } = getValues('filters.assumptionsData', {});

  if (customChargerInstallationCostToggler) {
    return;
  }

  const { vehicleSets } = watchedValues;

  if (isEmpty(vehicleSets)) return;

  const { getInstallationCosts } = calculations;
  const input = vehicleSets.map(convertToVehicleSet);
  const calculatedChargerInstallationCost = getInstallationCosts({
    input,
    level2ChargersInstallationCost,
    dcfcChargersInstallationCost,
  });
  const formattedCalculatedChargerInstallationCost = zappy.formatters.formatAsThousands(
    calculatedChargerInstallationCost
  );

  if (customChargerInstallationCost === calculatedChargerInstallationCost)
  {
    setValue(
      'filters.assumptionsData.customChargerInstallationCostFormatted',
      formattedCalculatedChargerInstallationCost
    );
    return;
  }

  setValue(
    'assumptionsData.chargerInstallationCost',
    calculatedChargerInstallationCost
  );
  setValue(
    'filters.assumptionsData.customChargerInstallationCost',
    calculatedChargerInstallationCost
  );

  setValue(
    'assumptionsData.customChargerInstallationCostFormatted',
    formattedCalculatedChargerInstallationCost
  );
  setValue(
    'filters.assumptionsData.customChargerInstallationCostFormatted',
    formattedCalculatedChargerInstallationCost
  );
}

const FORM_SET_VALUE = 'form/setValue';
function setFormValue({
  payload,
  setValue,
  getValues,
  preloads,
  calculations,
  watchedValues,
  values,
}) {
  const { name, value, func } = payload || {};
  const result =
    value ||
    (func &&
      // eslint-disable-next-line no-eval
      eval(
        `${func}({ getValues, preloads, calculations, watchedValues, values })`
      ));
  setValue(name, result);
}

export const SET_DEFAULT_ASSUMPTIONS_DATA = 'form/setDefaultAssumptionsData';
export function setDefaultAssumptionsData({
  reset,
  getValues,
  defaultValues,
  preloads: { location, power_suppliers = [] },
  changeResourceState,
  payload = {},
}) {
  const {
    assumptionsData,
    filters,
    catalogs,
    type,
    subtype,
    handle,
    input,
    output,
    zipcodeChanged,
    ...vehicleSetData
  } = getValues();

  const { update } = payload;
  // This gets ready when assumptions data gets merged with location data from api
  if (assumptionsData.isReady && !update) return;

  const zipcodeHasChanged =
    zipcodeChanged || vehicleSetData.zipcode !== filters.zipcode;

  const updatedAssumptions =
    !update || zipcodeHasChanged
      ? getAssumptionsFromLocations(location, assumptionsData)
      : {};

  const newRatePrice = assumptionsData.customRatePrice || updatedAssumptions.customRatePrice || updatedAssumptions.electricityPrice;
  const newAssumptions = merge(
    { isReady: true },
    assumptionsData,
    updatedAssumptions,
    {
      level2ChargersInstallationCostFormatted: zappy.formatters.formatAsDollars(
        assumptionsData.level2ChargersInstallationCost
      ),
      dcfcChargersInstallationCostFormatted: zappy.formatters.formatAsDollars(
        assumptionsData.dcfcChargersInstallationCost
      ),
      customRatePrice: newRatePrice,
      electricityPrice: newRatePrice,
    }
  );

  const newRateSelection = {
    ...vehicleSetData.rateSelection,
    customRatePrice: Number(newAssumptions.electricityPrice?.toFixed(4) || 0),
  };

  let selectedUtilityName = newAssumptions.selectedUtilityName;
  const utility = power_suppliers.find(
    (o) => o.name === selectedUtilityName
  )?.name;

  if (!utility) {
    selectedUtilityName = power_suppliers[0]?.name;

    newAssumptions.selectedUtilityName = selectedUtilityName;
  }

  const updatedFormValues = {
    ...vehicleSetData,
    assumptionsData: newAssumptions,
    rateSelection: newRateSelection,
    filters: {
      ...filters,
      customEmissions: filters.customEmissions,
    },
  };

  const newFilters = replaceValuesOfMatchingProps(filters, updatedFormValues);

  if (
    newFilters.organizationType !== 'public_school_districts' &&
    newFilters.isPrioritySchoolDistrict
  ) {
    newFilters.isPrioritySchoolDistrict = false;
  }
  newFilters.customEmissions = filters.customEmissions;

  const calculatedValues = pick(
    vehicleSetData,
    CALCULATED_FIELDS_TO_KEEP_VALUES
  );

  //CustomEmissions
  const customEmissions =
    vehicleSetData.customEmissions ??
    customEmissionsForDefaultAssumptions({
      dieselEmissionsinLbsPerGal:
        vehicleSetData.constants.dieselEmissionsinLbsPerGal,
      gasolineEmissionsInLbsPerGal:
        vehicleSetData.constants.gasolineEmissionsInLbsPerGal,
      electricityEmissions: updatedAssumptions.electricityEmissions,
      isDiesel: output?.fuel === 'diesel',
    });


  reset(
    {
      ...(update ? vehicleSetData : defaultValues),
      ...calculatedValues,
      type,
      subtype,
      handle,
      input,
      output,
      rateSelection: newRateSelection,
      assumptionsData: newAssumptions,
      filters: newFilters,
      catalogs,
      zipcodeChanged: false,
      customEmissions,
    },
    /**
     * keepIsValid is need because react-hook-form takes it into account
     * to validate that state of the input is mount or not, then based on that
     * use return default values or current values on getValues
     *  */
    { keepDefaultValues: update, keepIsValid: update }
  );

  changeResourceState(stateAttrs.IS_ASSUMPTIONS_DATA_READY, true);
}

const FORM_UPDATE_INPUT = 'form/updateInput';
function updateInput({ setValue, preloads, values }) {
  const output = findEquivalentICEforEV(values, preloads);

  const extraKeysToUpdate = ['type', 'subtype', 'handle'];
  extraKeysToUpdate.forEach((key) => {
    setValue(key, values[key]);
  });

  setValue('input', omit(values, 'equivalent_gas_vehicle'));
  setValue('output', output);
}

export const NAVIGATE_TO = 'form/navigateTo';
export const NAVIGATION_REDIRECTION_PROP = 'from';
function navigateTo({ payload, navigate, setValue, getValues }) {
  const { from, to, redirectsToSavedState } = payload || {};

  if (redirectsToSavedState) {
    const redirection = getValues(NAVIGATION_REDIRECTION_PROP);
    if (redirection) {
      setValue(NAVIGATION_REDIRECTION_PROP, null);
      navigate(redirection);
      return;
    }
  }

  if (from) {
    setValue(NAVIGATION_REDIRECTION_PROP, from);
  }

  navigate(to);
}

export const FORM_UPDATE_CHARGER = 'form/updateCharger';
function updateCharger({
  preloads,
  getValues,
  setValue,
  values,
  changeResourceState,
}) {
  const { unified_chargers: chargers } = preloads;
  const { selectedSetId, catalogs, vehicleSets } = getValues();
  const { referenceIndex } = catalogs;
  const currentVehicleSetIndex =
    vehicleSets.findIndex(({ id }) => id === selectedSetId) || 0;
  const currentVehicleSet = { ...vehicleSets[currentVehicleSetIndex] };

  const chargingWindowToUpdate =
    currentVehicleSet.chargingWindows[referenceIndex];
  const newChargerId = get(values, 'globalData[id]');
  const newChargerData = chargers.find(({ id }) => id === newChargerId);

  if (newChargerData) {
    currentVehicleSet.chargingWindows[referenceIndex] = {
      ...chargingWindowToUpdate,
      charger: newChargerData,
      chargerId: newChargerId,
    };

    setValue('catalogs.referenceId', null);
    setValue('catalogs.referenceIndex', null);
    setValue(`vehicleSets[${currentVehicleSetIndex}]`, currentVehicleSet);

    changeResourceState(stateAttrs.IS_PROJECT_READY, false);
  }
}

export const FORM_ADD_VEHICLE_SET = 'form/addVehicleSet';
function addVehicleSet({
  setValue,
  getValues,
  navigate,
  location,
  changeResourceState,
  sendAnalyticsEvent,
}) {
  const allValues = getValues();

  const { editingVehicleSetIndex, vehicleSets, input } = allValues;
  const editIndexIsValid =
    Number.isInteger(editingVehicleSetIndex) &&
    vehicleSets[editingVehicleSetIndex];

  const vehicleSetToAddOrUpdate = omit(
    allValues,
    FIELDS_TO_OMIT_FROM_VEHICLE_SET
  );
  vehicleSetToAddOrUpdate.vehicleCount = vehicleSetToAddOrUpdate.vehicleCount * 1;

  if (editIndexIsValid)
    vehicleSets[editingVehicleSetIndex] = vehicleSetToAddOrUpdate;
  else {
    vehicleSets.push({
      ...vehicleSetToAddOrUpdate,
      handle: input.handle,
      id: uuidv4(),
    });
  }

  setValue(VEHICLE_SETS_ATTR_NAME, vehicleSets);
  setValue(EDIT_VEHICLE_SET_INDEX_ATTR_NAME, null);
  sendAnalyticsEvent(
    {
      category: 'Project Update',
      action: 'Set Project',
    },
    vehicleSets
  );

  changeResourceState(stateAttrs.IS_PROJECT_READY, false);

  if (!location?.pathname.includes('/output')) navigate('/output');
}

const FORM_EDIT_VEHICLE_SET = 'form/editVehicleSet';
function editVehicleSet({
  getValues,
  defaultValues,
  setValue,
  values,
  navigate,
  reset,
  translate,
}) {
  const { editingVehicleSetIndex } = values || {};
  const allValues = getValues();
  const { vehicleSets, defaultVehicles } = allValues;

  const editIndexIsValid =
    Number.isInteger(editingVehicleSetIndex) &&
    vehicleSets[editingVehicleSetIndex];

  if (editIndexIsValid) {
    Object.entries(vehicleSets[editingVehicleSetIndex]).forEach(
      ([name, value]) => setValue(name, value)
    );
    setValue(EDIT_VEHICLE_SET_INDEX_ATTR_NAME, editingVehicleSetIndex);
  } else {
    const defaultFilteredValues = omit(defaultValues, [
      ...FIELDS_TO_OMIT_FROM_VEHICLE_SET,
      'input',
      'output',
      'languageSelected',
    ]);

    reset(
      {
        ...allValues,
        ...defaultFilteredValues,
        ...defaultVehicles,
        editingVehicleSetIndex: null,
        vehicleSetName: `${translate({
          id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1.vehicleSet',
          fallback: 'Vehicle Set',
        })} #${vehicleSets.length + 1}`,
      },
      { keepDefaultValues: true }
    );
  }

  navigate('/vehicleSet/edit');
}

const FORM_REMOVE_VEHICLE_SET = 'form/removeVehicleSet';
function removeVehicleSet({
  setValue,
  getValues,
  navigate,
  sendAnalyticsEvent,
  changeResourceState,
}) {
  const { editingVehicleSetIndex, vehicleSets } = getValues();

  const editIndexIsValid =
    Number.isInteger(editingVehicleSetIndex) &&
    vehicleSets[editingVehicleSetIndex];

  if (!editIndexIsValid) {
    navigate('/output');
    return;
  }

  const newVehicleSets = vehicleSets.filter(
    (_, index) => index !== editingVehicleSetIndex
  );

  setValue(VEHICLE_SETS_ATTR_NAME, newVehicleSets);
  setValue(EDIT_VEHICLE_SET_INDEX_ATTR_NAME, null);

  sendAnalyticsEvent(
    {
      category: 'Project Update',
      action: 'Set Project',
    },
    newVehicleSets
  );

  changeResourceState(stateAttrs.IS_PROJECT_READY, false);
  navigate('/output');
}

const FORM_CANCEL_VEHICLE_SET_EDIT = 'form/cancelVehicleSetEdit';
function cancelVehicleSetEdit({ setValue }) {
  setValue('editingVehicleSetIndex', null);
}

export const UPDATE_CALCULATIONS_INPUTS = 'calculations/updateInputs';
function updateCalculationsInput({
  getValues,
  setValue,
  setCalculationsInput,
  saveToLocalStorage,
  appState,
  changeResourceState,
  calculations,
}) {
  if (!appState.isReady) return;
  
  prepareAndSetCalculationsInput({
    getValues,
    setValue,
    setCalculationsInput,
    saveToLocalStorage,
    changeResourceState,
    appState,
    calculations,
  });
}

function prepareAndSetCalculationsInput({
  getValues,
  setValue,
  setCalculationsInput,
  saveToLocalStorage,
  changeResourceState,
  appState,
  location,
  calculations,
}) {
  const selectedUtilityName = getValues('assumptionsData.selectedUtilityName');
  const selectedRateId = getValues('assumptionsData.selectedRateId');
  const { customIce, isDiesel, toggleIce } =
    getValues('filters.customEmissions') || {};

  console.log(
    '>>>> prepareAndSetCalculationsInput (RUNNING)',
    selectedUtilityName,
    selectedRateId
  );
  setValue(
    'assumptionsData.isExternalRateEngine',
    !['simple'].includes(selectedRateId)
  );
  const { constants, assumptionsData, vehicleSets, finance } = getValues();

  let vehicleSetsParsed = vehicleSets.map((set) =>
    convertToVehicleSet({ ...set, location })
  );

  if (calculations && finance) {
    vehicleSetsParsed = vehicleSetsParsed.map((vehicleSet, index) => {
      let financeParams = {
        operationYears: assumptionsData.lifespanYears,
        input: cloneDeep(vehicleSet),
      };

      financeParams.input.finance.annualFees = Number(
        financeParams.input.finance.annualFees
      );

      let financeRes = calculations.finance(financeParams);
      setValue(`vehicleSets[${index}].financeResult`, financeRes[0]);

      vehicleSet.replacementVehicle.msrp =
        (financeRes[0].electricVehicle[
          financeParams.input.finance.financeType.toLowerCase()
        ].totalCostToOwn -
        (assumptionsData.lifespanYears *
          vehicleSet.finance.annualFees *
          vehicleSet.vehicleCount)) / vehicleSet.vehicleCount

      vehicleSet.existingVehicle.msrp =
       ( financeRes[0].fossilVehicle[
          financeParams.input.finance.financeType.toLowerCase()
        ].totalCostToOwn -
        (assumptionsData.lifespanYears *
          vehicleSet.finance.annualFees *
          vehicleSet.vehicleCount)) / vehicleSet.vehicleCount

      return vehicleSet;
    });
  }

  const customOrElectricityPriceKey =
    getValues('assumptionsData.selectedRateId') === 'simple'
      ? 'customRatePrice'
      : 'electricityPrice';
  const electricityPrice = getValues(
    `assumptionsData.${customOrElectricityPriceKey}`
  );

  const {
    customChargerInstallationCostToggler,
    chargerInstallationCost,
    customChargerInstallationCost,
  } = assumptionsData;

  const installationCost = customChargerInstallationCostToggler
    ? parseInt(customChargerInstallationCost)
    : chargerInstallationCost;

  const constantsUpdated = {
    ...constants,
    ...(toggleIce && isDiesel && { dieselEmissionsinLbsPerGal: customIce }),
    ...(toggleIce && !isDiesel && { gasolineEmissionsInLbsPerGal: customIce }),
  };

  setCalculationsInput({
    vehicleSetData: vehicleSetsParsed,
    assumptionsData: {
      ...assumptionsData,
      chargerInstallationCost: installationCost,
      electricityPrice,
      selectedUtilityName,
      postcode: getValues('filters.zipcode'),
      isExternalRateEngine: false,
    },
    constants: constantsUpdated,
  });

  localStorageSave({ getValues, saveToLocalStorage, appState });

  changeResourceState(stateAttrs.IS_PROJECT_READY, true);
  changeResourceState(stateAttrs.IS_COMSUMER_DATA_SENT, false);
}

export const API_REQUEST_SET_PROJECT = 'api/request/setProject';
function setProject({
  setValue,
  getValues,
  preloads: { location },
  setCalculationsInput,
  calculations,
  saveToLocalStorage,
  changeResourceState,
  appState,
  clearErrors,
  reset,
}) {
  console.log('>>>> API_REQUEST_SET_PROJECT (RUNNING)');
  const { vehicleSets, ...vehicleSetData } = getValues();
  window.globalLoading(true);

  const projectPayload = prepareProject(
    location,
    vehicleSetData,
    vehicleSets,
    calculations.totalKwh
  );

  fetchProject(projectPayload).then((data) => {
    const { incentives } = data?.evaluations || [];
    setValue('assumptionsData.incentives', incentives);

    prepareAndSetCalculationsInput({
      getValues,
      setValue,
      setCalculationsInput,
      saveToLocalStorage,
      changeResourceState,
      appState,
      location,
      calculations,
    });
    clearErrors();
  }).finally(() => {
    const formValues = getValues();
    const formAfterFiltersApplied = merge(
      {},
      formValues,
      {
        ...formValues.filters,
        customEmissions: formValues.customEmissions
      }
    );
    formAfterFiltersApplied.zipcodeChanged =
      formValues.zipcode !== formValues.filters.zipcode;

      reset(formAfterFiltersApplied, {
        keepIsValid: true,
        keepDirty: false,
        keepDefaultValues: false,
      });
  });
}

const VIEW_LOADING = 'view/loading';
function setLoading({ payload }) {
  const { isLoading } = payload;
  window.globalLoading(isLoading);
}

export const SET_DEFAULT_INPUT_OUTPUT = 'form/setDefaultInputOutput';
function setDefaultInputOutput({
  getValues,
  preloads,
  reset,
  appState,
  changeResourceState,
  payload,
}) {
  const { ev, ice, forceUpdate, path } = payload;
  const allValues = getValues();
  const pathname = path || window.location.pathname;
  const isIce = pathname.includes('fossilFuelModel')
  let { input:_input, output:_output } = isIce ? ice : ev;
  const input = isIce ? _output : _input;
  const output = isIce ? _input : _output;
  if (!forceUpdate && (
    appState.isInputOutputReady ||
    isEmpty(preloads) ||
    isEmpty(preloads.generic_vehicles) ||
    isEmpty(preloads.electric_vehicles) ||
    isEmpty(preloads.fossil_vehicles)
  ))
    return;

  const inputDetails = get(
    preloads,
    input.preload || 'generic_vehicles.ev'
  ).find(({ handle }) => handle === input.handle);
  const outputDetails = get(
    preloads,
    output.preload || 'generic_vehicles.ice'
  ).find(({ handle }) => handle === output.handle);

  const keepDefaultValues =
    appState.isLoadedFromSharedLink ||
    appState.isLoadedFromLocalStorage ||
    !appState.isStoredDataSetupDone ||
    forceUpdate;

  const evSelectionDetails = {
    type: (isIce ? outputDetails : inputDetails).type,
    subtype: (isIce ? outputDetails : inputDetails).subtype,
    handle: (isIce ? outputDetails : inputDetails).handle,
    input: {
      isReady: true,
      ...inputDetails,
    },
    output: {
      isReady: true,
      ...outputDetails,
    },
    ...(!allValues.defaultVehicles && {
      defaultVehicles: {
        isReady: false,
        input: inputDetails,
        output: outputDetails,
        ice,
        ev,
      }
    })
  };

  const values = {
    ...allValues,
    ...evSelectionDetails,
  };
  reset(values, { keepDefaultValues, keepDirty: false, keepIsValid: true });
  changeResourceState(stateAttrs.IS_INPUT_OUTPUT_READY, true);
}

export const LOCAL_STORAGE_SAVE = 'form/localStorageSave';
function localStorageSave({ getValues, saveToLocalStorage, appState }) {
  if (!appState.isSaveToLocalStorageEnabled) return;

  const values = omit(getValues(), [
    'catalogs',
    'constants',
    'encodedPowerSuppliers',
    'powerSuppliers',
    'input',
    'output',
  ]);

  return saveToLocalStorage(values);
}

export const LOCAL_STORAGE_CLEAR = 'form/localStorageClear';
function localStorageClear({ removeFromLocalStorage }) {
  removeFromLocalStorage();
}

export const LOCAL_STORAGE_LOAD = 'form/localStorageLoad';
function localStorageLoad({
  getLocalStorage,
  reset,
  getValues,
  changeResourceState,
  changeAppStatus,
  appState,
  sharedProjectData,
}) {
  if (appState.isStoredDataSetupDone) return;

  try {
    const localStorageValues = getLocalStorage(sharedProjectData);

    if (localStorageValues) {
      const baseValues = getValues();
      reset(
        { ...baseValues, ...localStorageValues },
        { keepDefaultValues: false, keepIsValid: true, keepDirty: false, keepDirtyValues: false}
      );
      changeResourceState(stateAttrs.IS_LOADED_FROM_LOCALSTORAGE, true);
    }

    changeAppStatus(status.STORED_DATA_SETUP_DONE);
  } catch (error) {
    if (sharedProjectData) {
      alert('Shared link you are trying to use is broken, please start over');
    }
    changeAppStatus(status.STORED_DATA_SETUP_DONE);
  }
}

export const START_OVER = 'form/startOver';
function startOver({
  reset,
  removeFromLocalStorage,
  setCalculationsInput,
  navigate,
  changeAppStatus,
  formDefaultValues,
  onClick,
  getValues
}) {
  onClick && onClick();
  const languageSelected = getValues('languageSelected')
  reset({...formDefaultValues, languageSelected}); //keep the selected language when starting over  
  localStorageClear({ removeFromLocalStorage });
  setCalculationsInput(undefined);
  navigate('../onboarding/intro');
  window.globalLoading(true);
  changeAppStatus(status.START_OVER);
  setTimeout(()=> {
    window.location.reload();
  }, 0);
}

export const FILTERS_APPLY = 'form/filters/apply';
function applyFilters({ reset, getValues, setValue, changeResourceState }) {
  const {
    customChargerInstallationCostFormatted,
    customChargerInstallationCost,
  } = getValues('filters.assumptionsData', {});
  const parsedCustomChargerInstallationCost = cleanupNumber(customChargerInstallationCostFormatted);
  const { toggleIce, toggleElectric, customIce, customElectric, isDiesel } =
    getValues('filters.customEmissions');
  const {
    dieselEmissionsinLbsPerGal,
    gasolineEmissionsInLbsPerGal,
    customElectric: originalCustomElectric,
  } = getValues('customEmissions');

  if (
    customChargerInstallationCostFormatted &&
    parsedCustomChargerInstallationCost !== customChargerInstallationCost
  ) {
    setValue(
      'filters.assumptionsData.customChargerInstallationCost',
      parsedCustomChargerInstallationCost
    );
  }

  setValue(
    isDiesel
      ? 'constants.dieselEmissionsinLbsPerGal'
      : 'constants.gasolineEmissionsInLbsPerGal',
    toggleIce
      ? customIce
      : isDiesel
        ? dieselEmissionsinLbsPerGal
        : gasolineEmissionsInLbsPerGal
  );

  setValue(
    'assumptionsData.electricityEmissions',
    toggleElectric ? customElectric : originalCustomElectric
  );

  const formValues = getValues();
  const formAfterFiltersApplied = merge(
    {},
    formValues,
    formValues.filters,
    {
      customEmissions: formValues.customEmissions
    });

  formAfterFiltersApplied.zipcodeChanged =
    formValues.zipcode !== formValues.filters.zipcode;

  window.globalLoading(true);

  /**
   * keepIsValid is need because react-hook-form takes it into account
   * to validate that state of the input is mount or not, then based on that
   * use return default values or current values on getValues
   *  */
  reset(formAfterFiltersApplied, {
    keepDefaultValues: true,
    keepIsValid: true,
  });

  setTimeout(() => {
    changeResourceState(stateAttrs.IS_FILTERS_CHANGED, true);
  }, 100);
}

const debouncedAnalyticsTrack = debounce(
  ({ payload, watchedValues, defaultValues, sendAnalyticsEvent }) => {
    const { pick, ...analyticsProps } = payload;

    if (pick && analyticsProps.label === '{{picked}}') {
      const newValue = get(watchedValues, pick, '');
      const defaultValue = get(defaultValues, pick, '');
      if (!newValue || newValue === defaultValue) return;

      analyticsProps.label = newValue.toString();
    }

    sendAnalyticsEvent(analyticsProps);
  },
  1000
);

export const ANALYTICS_TRACK = 'form/analyticsTrack';
function analyticsTrack({
  payload,
  watchedValues,
  defaultValues,
  sendAnalyticsEvent,
}) {
  debouncedAnalyticsTrack({
    payload,
    watchedValues,
    defaultValues,
    sendAnalyticsEvent,
  });
}

const CATALOG_SHOW_FILTERS = 'catalog/showFilters';
function catalogShowFilters() {
  const container = document.querySelector(
    '[data-design="app.catalog.vehicles.frame"]'
  );

  if (!container) {
    return;
  }

  if (container.classList.contains('display-filters')) {
    container.classList.remove('display-filters');
    return;
  }

  container.classList.add('display-filters');
}

const CATALOG_SHOW_CHARGER_FILTERS = 'catalog/showChargerFilters';
function catalogShowChargerFilters() {
  console.log('filters');
  const container = document.querySelector(
    '[data-design="app.catalog.chargers.frame"]'
  );

  if (!container) {
    return;
  }

  if (container.classList.contains('display-filters')) {
    container.classList.remove('display-filters');
    return;
  }

  container.classList.add('display-filters');
}
const OUTPUT_TOGGLE_FILTERS = 'output/toggleFilters';
function outputToggleFilters() {
  const container = document.querySelector(
    '[data-design="app.output.template"]'
  );

  // we need to make sure that we have container
  if (container) {
    if (container.classList.contains('display-filters')) {
      container.classList.remove('display-filters');
      return;
    }
    container.classList.add('display-filters');
  }
}

const OUTPUT_TOGGLE_NAVIGATION = 'output/toggleNavigation';
export function outputToggleNavigation() {
  const container = document.querySelector(
    '[data-design="app.output.template"]'
  );

  const mainContainer = document.querySelector('[data-design="app.output"]');

  if (!container || !mainContainer) {
    return;
  }

  if (
    container.classList.contains('display-navigation') &&
    mainContainer.classList.contains('display-navigation')
  ) {
    mainContainer.classList.remove('display-navigation');
    container.classList.remove('display-navigation');
    return;
  }

  container.classList.add('display-navigation');
  mainContainer.classList.add('display-navigation');
}

let readSharedProjectQueryDone = false;
export const SHARED_PROJECT_READ_QUERY = 'sharedProject/readQuery';
function readSharedProjectQuery({
  getLocalStorage,
  reset,
  getValues,
  changeAppStatus,
  changeResourceState,
  appState,
}) {
  // This is needed because it runs twice due to route replacing
  if (readSharedProjectQueryDone) return;
  readSharedProjectQueryDone = true;

  try {
    const query = new URLSearchParams(window.location.search);
    const sharedProject = decodeURI(query.get('sharedProject') || '');

    if (appState.isLoadedFromSharedLink === null && sharedProject) {
      localStorageLoad({
        getLocalStorage,
        reset,
        getValues,
        changeResourceState,
        changeAppStatus,
        appState,
        sharedProjectData: sharedProject,
      });
      changeResourceState(stateAttrs.IS_LOADED_FROM_SHARED_LINK, true);
      return;
    }

    changeResourceState(stateAttrs.IS_LOADED_FROM_SHARED_LINK, false);
  } catch (error) {
    alert('Shared link you are trying to use is broken, please start over');
    changeAppStatus(status.STORED_DATA_SETUP_DONE);
  }
}

const GO_TO_TOP = 'form/goToTop';
export function formGoToTop() {
  const mainContainer = document.getElementById('root');

  if (!mainContainer) {
    return;
  }
  mainContainer.scrollTop = 0;
}

const VALIDATE_TOTAL_RANGE = 'form/validateTotalRange';
export function validateTotalRange({ getValues, setValue, defaultValues }) {
  const { input } = getValues();
  const newMilesPerWorkday =
    input.total_range <=
    defaultValues.milesPerWorkday + defaultValues.personalMilesPerWorkday
      ? input.total_range
      : defaultValues.milesPerWorkday;

  const newPersonalMilesPerWorkday =
    newMilesPerWorkday !== defaultValues.milesPerWorkday
      ? 0
      : defaultValues.personalMilesPerWorkday;

  setValue('milesPerWorkday', newMilesPerWorkday);
  setValue('personalMilesPerWorkday', newPersonalMilesPerWorkday);
}

export const PRINT_PREVIEW = 'print/preview';
export function printPreview({ navigate }) {
  navigate('/print/output');
}

const actionsHandlers = {
  [TOGGLE_EMISSIONS]: toggleCustomEmissions,
  [FORM_SET_CUSTOM_EMISSIONS]: setCustomEmissions,
  [FORM_SET_CHARGER_INSTALLATION_COSTS]: setChargerInstallationCosts,
  [FORM_SET_AVG_DISTANCE_PER_DAY]: setAvgDistancePerDay,
  [FORM_SET_KWH_PER_DAY_NEEEDED]: setKwhPerDayNeeded,
  [FORM_SET_VALUE]: setFormValue,
  [API_REQUEST_SET_PROJECT]: setProject,
  [FORM_ADD_VEHICLE_SET]: addVehicleSet,
  [SET_DEFAULT_ASSUMPTIONS_DATA]: setDefaultAssumptionsData,
  [VIEW_LOADING]: setLoading,
  [FORM_EDIT_VEHICLE_SET]: editVehicleSet,
  [FORM_REMOVE_VEHICLE_SET]: removeVehicleSet,
  [FORM_CANCEL_VEHICLE_SET_EDIT]: cancelVehicleSetEdit,
  [UPDATE_CALCULATIONS_INPUTS]: updateCalculationsInput,
  [FORM_UPDATE_INPUT]: updateInput,
  [SET_DEFAULT_INPUT_OUTPUT]: setDefaultInputOutput,
  [NAVIGATE_TO]: navigateTo,
  [FORM_UPDATE_CHARGER]: updateCharger,
  [LOCAL_STORAGE_SAVE]: localStorageSave,
  [LOCAL_STORAGE_CLEAR]: localStorageClear,
  [LOCAL_STORAGE_LOAD]: localStorageLoad,
  [START_OVER]: startOver,
  [FILTERS_APPLY]: applyFilters,
  [ANALYTICS_TRACK]: analyticsTrack,
  [CATALOG_SHOW_FILTERS]: catalogShowFilters,
  [SHARED_PROJECT_READ_QUERY]: readSharedProjectQuery,
  [OUTPUT_TOGGLE_FILTERS]: outputToggleFilters,
  [OUTPUT_TOGGLE_NAVIGATION]: outputToggleNavigation,
  [GO_TO_TOP]: formGoToTop,
  [VALIDATE_TOTAL_RANGE]: validateTotalRange,
  [CATALOG_SHOW_CHARGER_FILTERS]: catalogShowChargerFilters,
  [PRINT_PREVIEW]: printPreview,
};

export default actionsHandlers;
