import { DESKTOP_THRESHOLD } from "../../base";

const ONBOARDING_INFO = {
  'app.onboarding.info': {
    _extends: 'app.page.common',
    config: {
      content: [
        ['TranslatedText', 'app.onboarding.info.heading'],
        ['TranslatedText', 'app.onboarding.info.description'],
        ['ContentContainer', 'app.onboarding.info.form'],
      ],
    },
  },
  'app.onboarding.info.heading': {
    _extends: 'app.onboarding.common.heading',
    config: {
      i18n: {
        id: 'i18n.onboarding.info.heading',
        fallback: 'General Information',
      },
    },
  },
  'app.onboarding.info.description': {
    _extends: 'app.onboarding.common.description',
    config: {
      i18n: {
        id: 'i18n.onboarding.info.description',
        fallback:
          'We use this information to calculate fuel costs and applicable incentives.',
      },
    },
  },
  'app.onboarding.info.form': {
    _extends: 'app.onboarding.common.form',
    config: {
      as: 'form',
      content: [
        ['ContentContainer', 'app.onboarding.info.form.fields'],
        ['ContentContainer', 'app.onboarding.info.form.footer'],
      ],
    },
  },
  'app.onboarding.info.form.fields': {
    config: {
      content: [
        [
          'ProjectOrganizationType',
          'app.onboarding.info.form.organizationType',
        ],
        [
          'InputWithToggleCheckbox',
          'app.onboarding.info.form.onboardingZipcode',
        ],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        'align-items': 'flex-start',
        backgroundColor: 'white',

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          padding: '4.93vh 7.64vw',
          'min-height': '37vh',
          width: '80vw',
          'grid-template-columns': 'repeat(2, 1fr)',
          'grid-column-gap': '16.24vw',
        },

        '@media (max-width: 992px)': {
          'grid-row-gap': '24px',
          padding: '4.93vh 0',
        },
      },
    },
  },
  'app.onboarding.info.form.onboardingZipcode': {
    _extends: 'app.onboarding.common.InputWithToggleCheckbox',
    components: {
      input: 'app.onboarding.info.form.onboardingZipcode.zipcode',
      checkbox: 'app.onboarding.info.form.onboardingZipcode.forMultipleSites',
    },
  },
  'app.onboarding.info.form.onboardingZipcode.zipcode': {
    _extends: 'app.onboarding.common.InputWithToggleCheckbox.input',
    config: {
      watch: {
        name: ['powerSuppliers'],
      },
      label: {
        htmlFor: 'zipcode',
        i18n: {
          id: 'i18n.app.onboarding.info.form.onboardingZipcode.zipcode',
          fallback: 'Zip Code',
        },
      },
      input: {
        component: 'FormInput',
        name: 'zipcode',
        placeholder: {
          i18n: {
            id: 'i18n.onboarding.info.form.onboardingZipcode.input.placeholder',
            fallback: '99999 or A9A 9A9',
          },
        },
      },
      description: {
        i18n: {
          id: 'i18n.onboarding.info.form.onboardingZipcode.description',
          fallback: 'Used to check incentive applicability and fuel prices',
        },
      },
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.formProvider.form.field.input.errors.pattern',
            fallback: 'Zip Code is required',
          },
        },
        pattern: {
          value:
            '/^$|^\\d{5}(\\d{4}|-\\d{4})$|^\\d{5}$|(^[a-zA-Z][0-9][a-zA-Z]( |)(|[0-9][a-zA-Z][0-9])$)/',
          i18n: {
            id: 'i18n.formProvider.form.field.input.errors.pattern',
            fallback: 'Not a valid Zip Code',
          },
        },
        postcodeBySupplier: {
          i18n: {
            id: 'i18n.formProvider.form.field.input.errors.area',
            fallback:
              'Zip code is outside the service area of {{powerSuppliers}}. Only projects located within this service area are supported by this tool',
          },
        },
      },
    },
  },
  'app.onboarding.info.form.onboardingZipcode.forMultipleSites': {
    _extends: 'app.onboarding.common.InputWithToggleCheckbox.checkbox',
    config: {
      name: 'forMultipleSites',
    },
    components: {
      checkbox:
        'app.onboarding.info.form.onboardingZipcode.forMultipleSites.checkbox',
      label:
        'app.onboarding.info.form.onboardingZipcode.forMultipleSites.label',
    },
  },
  'app.onboarding.info.form.onboardingZipcode.forMultipleSites.checkbox': {
    schema: {
      default: {
        'margin-top': '4px',
        marginRight: '8px',
        cursor: 'pointer',

        '& > input': {
          position: 'inherit',
          visibility: 'visible',
        },
      },
    },
  },
  'app.onboarding.info.form.onboardingZipcode.forMultipleSites.label': {
    _extends: 'app.onboarding.common.InputWithToggleCheckbox.checkbox.label',
    config: {
      as: 'label',
      htmlFor: 'forMultipleSites',
      content: [
        [
          'TranslatedText',
          'app.onboarding.info.form.right.zipcode.checkbox.label.title',
        ],
        [
          'TranslatedText',
          'app.onboarding.info.form.right.zipcode.checkbox.label.description',
        ],
      ],
    },
  },
  'app.onboarding.info.form.right.zipcode.checkbox.label.title': {
    config: {
      fontSize: '.875rem',
      fontWeight: 'bold',
      i18n: {
        id: 'i18n.onboarding.info.form.right.zipcode.checkbox.label.title',
        fallback: 'I am purchasing for multiple sites',
      },
    },
    schema: {
      default: {
        display: 'block',
      },
    },
  },
  'app.onboarding.info.form.right.zipcode.checkbox.label.description': {
    config: {
      fontSize: '.875rem',
      color: 'var(--text-primary-color-light)',
      i18n: {
        id: 'i18n.onboarding.info.form.right.zipcode.checkbox.label.description',
        fallback:
          '(National average fuel prices will be applied and local incentives will not be analyzed)',
      },
    },
    schema: {
      default: {
        display: 'block',
      },
    },
  },
  'app.onboarding.info.form.organizationType': {
    config: {
      preload: 'organization_types',
    },
    components: {
      field: 'app.onboarding.info.form.organizationType.field',
      infoValue: 'app.onboarding.info.form.organizationType.infoValue',
      infoDescription:
        'app.onboarding.info.form.organizationType.infoDescription',
      checkbox: 'app.onboarding.info.form.organizationType.checkbox',
    },
  },
  'app.onboarding.info.form.organizationType.field': {
    _extends: 'app.common.field',
    components: {
      input: 'app.onboarding.info.form.organizationType.field.select',
      tooltip: 'app.onboarding.info.form.organizationType.field.tooltip',
    },
    config: {
      label: {
        htmlFor: 'organizationType',
        i18n: {
          id: 'i18n.onboarding.info.form.organizationType.field.label',
          fallback: 'Organization type',
        },
      },
      input: {
        name: 'organizationType',
        placeholder: {
          i18n: {
            id: 'i18n.onboarding.info.form.organizationType.field.placeholder',
            fallback: 'Select organization type',
          },
        },
      },
      description: {
        i18n: {
          id: 'i18n.onboarding.info.form.organizationType.field.description',
          fallback: 'Select an option to see definition',
        },
      },
    },
    schema: {
      default: {
        'grid-template-areas':
          '"label label" "input tooltip" "description description"',
      },
    },
  },
  'app.onboarding.info.form.organizationType.field.select': {
    _extends: 'app.common.select',
    config: {
      component: 'FormSelect',
      name: 'organizationType',
      preload: 'organization_types',
    },
  },
  'app.onboarding.info.form.organizationType.field.tooltip': {
    config: {
      event: 'hover',
    },
    components: {
      activator: 'app.common.tooltip.activator',
      popover:
        'app.onboarding.info.form.organizationType.field.tooltip.popover',
    },
    schema: {
      default: {
        'grid-area': 'tooltip',
        display: 'flex',
        marginLeft: '0.4rem',

        '@media (max-width: 992px)': {
          display: 'none',
        },
      },
    },
  },
  'app.onboarding.info.form.organizationType.field.tooltip.popover': {
    config: {
      position: 'NE',
      content: [
        [
          'TranslatedText',
          'app.onboarding.info.form.organizationType.field.tooltip.popover.translatedText',
        ],
      ],
    },
    schema: {
      default: {
        alignItems: 'center',
        backgroundColor: 'var(--primary-dark-color)',
        border: '1px solid grey',
        borderRadius: '0.4rem',
        display: 'flex',
        justifyContent: 'center',
        padding: '1.125rem',
        width: '20rem',
        marginLeft: '0.4rem',
        'box-shadow': '-4px 4px 4px 2px #2222',
      },
    },
  },
  'app.onboarding.info.form.organizationType.field.tooltip.popover.translatedText':
    {
      config: {
        element: 'span',
        fontSize: '14px',
        color: 'white',
        i18n: {
          id: 'i18n.tooltipIcon.popover.translatedText',
          fallback:
            'We ask for the organization type so we can identify applicable incentives',
        },
      },
    },
  'app.onboarding.info.form.organizationType.field.description': {
    _extends: 'app.common.field.description',
    config: {
      i18n: {
        id: 'i18n.onboarding.info.form.organizationType.label',
        fallback: 'Select an option to see definition',
      },
    },
    schema: {
      default: {
        'grid-area': 'description',
      },
    },
  },
  'app.onboarding.info.form.organizationType.infoValue': {
    config: {
      fontSize: '.875rem',
      fontWeight: 'bold',
    },
    schema: {
      default: {
        display: 'block',
        marginTop: '13px',

        '@media (max-width: 992px)': {
          display: 'none',
        },
      },
    },
  },
  'app.onboarding.info.form.organizationType.infoDescription': {
    config: {
      fontSize: '.875rem',
      color: 'var(--text-primary-color-light)',
    },
    schema: {
      default: {
        display: 'block',

        '@media (max-width: 992px)': {
          display: 'none',
        },
      },
    },
  },
  'app.onboarding.info.form.organizationType.checkbox.input': {
    _extends: 'app.onboarding.common.checkbox',
    config: {
      type: 'checkbox',
      component: 'FormInput',
      text: {
        htmlFor: 'filters.isPrioritySchoolDistrict',
        lineHeight: '16px',
        i18n: {
          id: 'i18n.onboarding.info.form.organizationType.checkbox.input',
          values: {
            url: 'http://some-url.com',
          },
          dangerouslySetInnerHTML: true,
          fallback:
            'Prioritized School District</br>Check the <a href="{{url}}" style="color: var(--color-info);text-decoration: underline;" target="_blank">list of prioritized school districts</a>',
        },
      },
      name: 'filters.isPrioritySchoolDistrict',
    },
    schema: {
      default: {
        'align-items': 'flex-start',
      },
    },
  },
  'app.onboarding.info.form.organizationType.checkbox': {
    _extends: 'app.common.field',
    components: {
      input: 'app.onboarding.info.form.organizationType.checkbox.input',
      description: null,
    },
    config: {
      label: null,
      input: {
        name: 'filters.isPrioritySchoolDistrict',
      },
    },
    schema: {
      default: {
        'margin-top': '16px',
      },
    },
  },
  'app.onboarding.info.form.footer': {
    _extends: 'app.onboarding.common.form.footer',
    config: {
      content: [
        ['ContentContainer', 'app.onboarding.common.footer.button.back'],
        ['SmartNextButton', 'app.onboarding.info.footer.smartButton'],
      ],
    },
  },
  'app.onboarding.info.footer.smartButton': {
    _extends: 'app.onboarding.common.footer.smartButton.next',
    config: {
      to: '../selection/evModel',
      disabled:
        '(function ({ organizationType, zipcode, forMultipleSites }, isValid, errors) { return !organizationType || (!zipcode && !forMultipleSites) || Object.keys(errors).length; })',
    },
  },
};

export default ONBOARDING_INFO;
